<template>
  <div class="m-shops-filters">
    <div
      v-if="false"
      class="reset-filters"
      @click="resetFilter"
    >
      {{ $t("Clear all") }}
    </div>
    <div
      v-if="isDesktop"
      class="desktop-filters"
    >
      <MInputAutocomplete
        :selected="selectedCity"
        :data-list="citiesList"
        :label="$t('City')"
        :message="$t('Enter the settlement from the list of results')"
        property="name"
        @set-value="changeCity"
      />
      <MInputAutocomplete
        :selected="selectedSchedule"
        :data-list="scheduleList"
        :label="$t('Work schedule')"
        :message="$t('Enter the schedule from the list of results')"
        property="name"
        @set-value="changeSchedule"
      />
      <div class="filter-toggles">
        <ACheckboxToggle
          class="filter-toggles__cafe"
          :title="$t('VARUS CAFE')"
          :value="filter.cafe"
          @input="changeVarusCafe"
        />
        <ACheckboxToggle
          class="filter-toggles__drive"
          title="VARUS Drive"
          :value="filter.drive"
          @input="changeVarusDrive"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DeviceType from 'theme/mixins/DeviceType';
import Shops from 'theme/mixins/Shops';
import ACheckboxToggle from 'theme/components/atoms/a-checkbox-toggle';
import MInputAutocomplete from 'theme/components/molecules/m-input-autocomplete';

export default {
  name: 'MShopsFilters',
  components: {
    ACheckboxToggle,
    MInputAutocomplete
  },
  mixins: [DeviceType, Shops]
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-shops-filters {
  position: relative;

  .reset-filters {
    position: absolute;
    top: -20px;
    left: 2px;
    cursor: pointer;
    font-size: 12px;
    color: var(--orange);
    display: inline-block;

    &:hover {
      color: var(--orange-hover);
    }
  }

  .desktop-filters {
    display: flex;
    gap: var(--spacer-15);

    &>div {
      flex-basis: 33%;
    }

    .filter-toggles {
      display: flex;
      background-color: var(--green-light-grayish2);
      align-items: center;
      padding: var(--spacer-10) 0;

      &>div {
        opacity: 1;
        flex-basis: 50%;
        display: flex;
        height: 100%;
        padding-left: var(--spacer-20);

        ::v-deep {
          .sf-checkbox__checkmark {
            &:hover {
              border-color: var(--gray);
              --checkbox-border-color: var(--gray);
              --checkbox-background: var(--gray);
            }

            &--is-active {
              --checkbox-border-color: var(--orange);
              --checkbox-background: var(--orange);

              &:hover {
                --checkbox-border-color: var(--orange-hover);
                --checkbox-background: var(--orange-hover);
              }
            }

            .sf-checkbox__label {
              color: var(--black)!important;
            }
          }
        }
      }

      &__cafe {
        border-right: 1px solid var(--gray3);
      }
    }
  }
}
</style>
