<template>
  <SfOSidebar
    :visible="isOpen"
    class="m-shops-filters-sidebar mobile-only"
    @close="close"
  >
    <template #bar>
      <SfBar class="sf-bar--filters">
        <template #title>
          <div class="sf-bar__title">
            {{ $t('Filters') }}
          </div>
        </template>
        <template #close>
          <div class="sf-bar__buttons">
            <SfButton
              v-if="filtersCount"
              class="sf-button--pure sf-bar__clear"
              @click="resetFilter"
            >
              {{ $t("Clear all") }}
            </SfButton>
            <SfButton
              class="sf-button--close"
              @click="close"
            >
              <SfIcon
                icon="cross"
                size="xxs"
                color="secondary"
              />
            </SfButton>
          </div>
        </template>
      </SfBar>
    </template>
    <div class="filters">
      <div class="m-shops-filters-sidebar__item">
        <MInputAutocomplete
          :selected="selectedCity"
          :data-list="citiesList"
          :label="$t('City')"
          :message="$t('Enter the settlement from the list of results')"
          property="name"
          @set-value="changeCity"
        />
      </div>
      <div class="m-shops-filters-sidebar__item">
        <MInputAutocomplete
          :selected="selectedSchedule"
          :data-list="scheduleList"
          :label="$t('Work schedule')"
          :message="$t('Enter the schedule from the list of results')"
          property="name"
          @set-value="changeSchedule"
        />
      </div>
      <div class="m-shops-filters-sidebar__item">
        <ACheckboxToggle
          class="filter-toggles__cafe"
          :title="$t('VARUS CAFE')"
          :value="filter.cafe"
          @input="changeVarusCafe"
        />
      </div>
      <div class="m-shops-filters-sidebar__item">
        <ACheckboxToggle
          class="filter-toggles__drive"
          title="VARUS Drive"
          :value="filter.drive"
          @input="changeVarusDrive"
        />
      </div>
    </div>
    <template #content-bottom>
      <div class="filters__buttons">
        <SfButton
          class="sf-button--primary sf-button--full-width"
          @click="close"
        >
          {{ $t("Done") }}
          <span
            v-if="filtersCount"
            class="filters__buttons--count"
          >
            ({{ filtersCount }})
          </span>
        </SfButton>
      </div>
    </template>
  </SfOSidebar>
</template>
<script>
import {
  SfBar,
  SfButton,
  SfIcon
} from '@storefront-ui/vue';
import Shops from 'theme/mixins/Shops';
import ACheckboxToggle from 'theme/components/atoms/a-checkbox-toggle';
import MInputAutocomplete from 'theme/components/molecules/m-input-autocomplete';
import SfOSidebar from 'theme/components/storefront-override/SfOSidebar';

export default {
  name: 'MShopsFiltersSidebar',
  components: {
    SfOSidebar,
    SfBar,
    SfButton,
    SfIcon,
    ACheckboxToggle,
    MInputAutocomplete
  },
  mixins: [Shops],
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isOpen (value) {
      if (value) {
        this.appliedFilter = JSON.parse(JSON.stringify(this.filter))
      }
    }
  },
  methods: {
    close () {
      this.applyFilter()
      this.clearFilter()
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-shops-filters-sidebar {
  ::v-deep {
    .sf-bar {
      --bar-height: 3.75rem;
      border-bottom: 2px solid var(--light-gray);

      &--filters {
        justify-content: flex-start;
        padding: var(--spacer-xs) var(--spacer-base);

        & > div {
          &:first-child {
            display: none;
          }
        }

        & > div {
          &:last-child {
            margin-left: auto;
          }
        }
      }

      &__title {
        font-size: 1.25rem;
        line-height: var(--spacer-base);
      }

      &__buttons {
        display: flex;
      }
    }

    .sf-checkbox__label {
      font-size: var(--font-size-13);
    }
  }

  .sf-bar__clear {
    font-size: var(--font-sm);
    color: var(--orange);
    font-weight: normal;
  }

  .sf-button--close {
    margin-left: 30px;
  }

  &__item {
    display: block;
    border-bottom: 1px solid var(--line-gray);
    padding: 0 0 var(--spacer-base) 0;
    margin-bottom: var(--spacer-base);
  }
}

::v-deep {
  .sf-sidebar__content {
    padding: var(--spacer-20);
    .sf-button {
      &:active {
        --button-background: none;
        --button-color: var(--c-text);
      }
    }
  }
}

::v-deep {
  .sf-bar__icon {
    display: none;
  }
}

.filters__buttons--count {
  margin-left: var(--spacer-10);
}
</style>
