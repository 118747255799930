<template>
  <SfSidebar
    :visible="isOpen"
    class="m-shop-info-sidebar mobile-only"
    @close="close"
  >
    <template #bar>
      <SfBar class="sf-bar--info">
        <template #title>
          <div class="sf-bar__title">
            {{ $t('Shop on map') }}
          </div>
        </template>
        <template #close>
          <div class="sf-bar__buttons">
            <SfButton
              class="sf-button--close"
              data-transaction-name="ShopInfo - Close Sidebar"
              @click="close"
            >
              <SfIcon
                icon="cross"
                size="xxs"
                color="secondary"
              />
            </SfButton>
          </div>
        </template>
      </SfBar>
    </template>
    <MShopInfo
      class="info-window__main"
      :location="selectedShop"
      @closeInfoWindow="close"
    />
  </SfSidebar>
</template>
<script>
import {
  SfSidebar,
  SfBar,
  SfButton,
  SfIcon
} from '@storefront-ui/vue';
import MShopInfo from 'theme/components/molecules/m-shop-info';

export default {
  name: 'MShopsFiltersSidebar',
  components: {
    SfSidebar,
    SfBar,
    SfButton,
    SfIcon,
    MShopInfo
  },
  props: {
    selectedShop: {
      type: Object,
      default: () => ({})
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-shop-info-sidebar {
  ::v-deep {
    .sf-bar {
      --bar-height: 3.75rem;
      border-bottom: 2px solid var(--light-gray);

      &--info {
        justify-content: flex-start;
        padding: var(--spacer-xs) var(--spacer-base);

        & > div {
          &:first-child {
            display: none;
          }
        }

        & > div {
          &:last-child {
            margin-left: auto;
          }
        }
      }

      &__title {
        font-size: 1.25rem;
        line-height: var(--spacer-base);
      }

      &__buttons {
        display: flex;
      }
    }
  }

  .sf-button--close {
    margin-left: 30px;
  }

  &__item {
    display: block;
    border-bottom: 1px solid var(--line-gray);
    padding: 0 0 var(--spacer-base) 0;
    margin-bottom: var(--spacer-base);
  }
}

::v-deep {
  .sf-sidebar__content {
    padding: var(--spacer-10);
    .sf-button {
      &:active {
        --button-background: none;
        --button-color: var(--c-text);
      }
    }
  }
}

::v-deep {
  .sf-bar__icon {
    display: none;
  }
}

</style>
