import { mapActions, mapGetters } from 'vuex';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'ShippingShopMixin',
  data () {
    return {
      loading: {
        general: true,
        shops: true
      },
      filter: {
        isDefault: false,
        searchString: '',
        city: '',
        schedule: '',
        cafe: false,
        drive: false
      },
      options: {
        isEnabled: false
      },
      cities: [],
      shops: []
    }
  },
  computed: {
    ...mapGetters({
      getShippingDetails: 'shipping/getShippingDetails',
      getDefaultCity: 'shipping-module/getDefaultCity'
    }),
    currentCity () {
      return this.getShippingDetails?.cityDetails || this.getDefaultCity
    },
    selectedCity () {
      return this.cities.find(e => e.id === this.filter.city) || this.currentCity
    }
  },
  methods: {
    ...mapActions({
      loadCityListPure: 'shipping-module/loadCityListPure',
      loadShopListPure: 'shipping-module/loadShopListPure'
    }),
    async loadShops () {
      this.loading.shops = true
      try {
        if (!this.cities.length) {
          this.cities = await this.loadCityListPure({ storeCode: currentStoreView().storeCode })
        }

        const { items: shops } = await this.loadShopListPure({
          cityId: this.filter.city,
          uploadToWeb: true,
          isEnabled: this.options.isEnabled,
          excludeFields: []
        })

        this.shops = shops
      } catch (e) {
        this.shops = []
      } finally {
        this.loading.shops = false
      }
    },
    onChangeCity (city) {
      this.filter.city = city.id

      return this.loadShops()
    },
    setFilters (filter) {
      this.filter = {
        ...this.filter,
        ...filter
      }
    }
  }
}
