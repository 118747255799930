<template>
  <div
    class="sf-sidebar"
    :class="[staticClass, className]"
  >
    <SfOOverlay
      :visible="visibleOverlay"
      @click="close"
    />
    <transition :name="transitionName">
      <aside
        v-if="visible"
        v-focus-trap
        class="sf-sidebar__aside"
      >
        <!--@slot Use this slot to place content inside the modal bar.-->
        <slot name="bar">
          <SfBar
            :title="title"
            class="mobile-only"
            :back="true"
            @click:back="close"
          />
        </slot>
        <!--@slot Use this slot to replace close icon.-->
        <slot
          name="circle-icon"
          v-bind="{ close, button }"
        >
          <SfCircleIcon
            v-if="button"
            icon-size="12px"
            aria-label="Close sidebar"
            icon="cross"
            class="sf-sidebar__circle-icon desktop-only"
            data-transaction-name="Sidebar Close"
            @click="close"
          />
        </slot>
        <div
          v-if="title || hasTop"
          class="sf-sidebar__top"
        >
          <!--@slot Use this slot to replace SfHeading component.-->
          <slot
            name="title"
            v-bind="{ title, subtitle, headingLevel }"
          >
            <SfHeading
              v-if="title"
              :title="title"
              :subtitle="subtitle"
              :level="headingLevel"
              class="sf-heading--left sf-heading--no-underline sf-sidebar__title desktop-only"
            />
          </slot>
          <!--@slot Use this slot to add sticky top content.-->
          <slot name="content-top" />
        </div>
        <div
          ref="content"
          class="sf-sidebar__content"
        >
          <!--@slot Use this slot to add SfSidebar content.-->
          <slot />
        </div>
        <!--@slot Use this slot to place content to sticky bottom.-->
        <div
          v-if="hasBottom"
          class="sf-sidebar__bottom"
        >
          <slot name="content-bottom" />
        </div>
      </aside>
    </transition>
  </div>
</template>
<script>
import { focusTrap } from '@storefront-ui/vue/src/utilities/directives';
import SfBar from '@storefront-ui/vue/src/components/molecules/SfBar/SfBar.vue';
import SfCircleIcon from '@storefront-ui/vue/src/components/atoms/SfCircleIcon/SfCircleIcon.vue';
import SfOOverlay from 'theme/components/storefront-override/SfOOverlay';
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';

export default {
  name: 'SfOSidebar',
  directives: { focusTrap },
  components: {
    SfBar,
    SfCircleIcon,
    SfOOverlay,
    SfHeading
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    headingLevel: {
      type: Number,
      default: 3
    },
    button: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      position: 'left',
      staticClass: null,
      className: null,
      body: null,
      scrollPosition: 0
    };
  },
  computed: {
    visibleOverlay () {
      return this.visible && this.overlay;
    },
    transitionName () {
      return 'slide-' + this.position;
    },
    hasTop () {
      return this.$slots.hasOwnProperty('content-top');
    },
    hasBottom () {
      return this.$slots.hasOwnProperty('content-bottom');
    }
  },
  watch: {
    visible: {
      handler (value) {
        if (!this.body) return;

        if (value) {
          this.$nextTick(() => {
            this.disableScroll()
          });
          document.addEventListener('keydown', this.keydownHandler);
        } else {
          this.enableScroll()
          document.removeEventListener('keydown', this.keydownHandler);
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.body = document.querySelector('body')
    this.classHandler();
  },
  updated () {
    this.classHandler();
  },
  methods: {
    close () {
      this.$emit('close');
    },
    keydownHandler (e) {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close();
      }
    },
    classHandler () {
      let update = false;

      if (this.staticClass !== this.$vnode.data.staticClass) {
        this.staticClass = this.$vnode.data.staticClass;
        update = true;
      }

      if (this.className !== this.$vnode.data.class) {
        this.className = this.$vnode.data.class;
        update = true;
      }

      if (update) {
        this.position =
          [this.staticClass, this.className].toString().search('--right') > -1
            ? 'right'
            : 'left';
      }
    },
    checkBodyScroll () {
      return [
        !!this.body.style.overflow,
        !!this.body.style.position,
        !!this.body.style.top,
        !!this.body.style.width
      ].includes(true)
    },
    disableScroll () {
      this.scrollPosition = window.pageYOffset;
      this.body.style.overflow = 'hidden';
      this.body.style.position = 'fixed';
      this.body.style.top = `-${this.scrollPosition}px`;
      this.body.style.width = '100%';
    },
    enableScroll () {
      if (!this.checkBodyScroll()) return
      this.body.style.removeProperty('overflow');
      this.body.style.removeProperty('position');
      this.body.style.removeProperty('top');
      this.body.style.removeProperty('width');
      window.scrollTo(0, this.scrollPosition);
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/organisms/SfSidebar.scss";
</style>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.sf-overlay {
  z-index: var(--sf-overlay-index);
}
</style>
