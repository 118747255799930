import { mergeMeta } from 'theme/meta/utils/mergeMeta';

export function metaShops () {
  const meta = {
    title: `${this.$t('Shops')} | VARUS`,
    meta: {
      description: {
        name: 'description',
        content: this.$t('META_OTHER_PAGES_DESCRIPTION')
      }
    }
  };

  return mergeMeta(meta)
}
