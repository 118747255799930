<template>
  <div class="m-shops-cities">
    <div class="accordion">
      <div
        v-for="city in cities"
        class="accordion-item"
        :key="city.id"
        data-transaction-name="ShopList - City"
        @click="onItemClick(city)"
      >
        {{ city.name }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MShopsCities',
  props: {
    cities: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      openedCategories: [],
      currentFilterCategories: []
    }
  },
  computed: {},
  methods: {
    onItemClick (city) {
      this.$emit('itemClick', city)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.m-shops-cities {
  margin: 0 0 var(--spacer-18);
  border-radius: var(--spacer-10);
  overflow: hidden;

  @media only screen and (min-width: $tablet-min) {
    margin: 0 0 var(--spacer-10);
  }

  ::v-deep {
    .accordion {
      font-family: Inter,serif;
      display: block;
      background: var(--accordion-background);
      width: auto;
      align-items: center;
      padding: var(--spacer-20);

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(235, 103, 71, 0.3);
      }

      &-item {
        font-family: var(--font-family-secondary);
        font-size: var(--spacer-14);
        line-height: var(--spacer-22);;
        color: var(--black);
        cursor: pointer;

        &:not(:first-child) {
          padding-top: var(--spacer-10);
        }

        &:hover {
          color: var(--orange)
        }
      }
    }
  }
}
</style>
