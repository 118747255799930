<template>
  <ASmoothReflow class="o-shops">
    <div
      v-if="isDesktop && !generalLoading"
      class="main__content"
    >
      <div class="shops-block">
        <div class="shops-address sf-input sf-input--filled">
          <SfInput
            v-if="pageType === 'shops'"
            v-model="filter.searchString"
            type="text"
            :label="$t('Enter shop address')"
          />
          <div
            v-if="pageType === 'shops'"
            class="search-icon"
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.5507 13.5943C14.9318 12.2616 15.7897 10.398 15.7897 8.33569C15.7897 4.2843 12.4789 1 8.39487 1C4.3108 1 1 4.2843 1 8.33569C1 12.3871 4.3108 15.6714 8.39487 15.6714C10.4 15.6714 12.2187 14.8797 13.5507 13.5943ZM13.5507 13.5943L19 19" stroke="black" />
            </svg>
          </div>
          <MInputAutocomplete
            v-if="pageType === 'product'"
            :selected="selectedCity"
            :data-list="cities"
            :label="$t('City')"
            :message="$t('Enter the settlement from the list of results')"
            property="name"
            @set-value="changeCity"
          />
        </div>
        <div class="address-separator" />
        <div class="shops-list" :class="{ 'shops-list--is-loading': shopsLoading }">
          <MShopListItem
            v-for="shop in filteredShops"
            :key="shop.id"
            :shop="shop"
            :selected-shop-id="selectedShopId"
            :need-to-show-open-block="pageType === 'product'"
            @shopClick="onShopClick"
            class="shop-item"
          />

          <ALoadingSpinner
            v-show="shopsLoading"
            :size="48"
            :weight="3"
            :is-absolute-position="true"
          />
        </div>
      </div>
      <div class="map">
        <OMap
          :city="selectedCity"
          :shops="filteredShops"
          :page-type="pageType"
          @open-info="openInfoWindow"
          @close-info="closeInfoWindow"
        />
        <div
          v-if="isShowInfoWindow"
          class="info-window"
        >
          <MShopInfo
            class="info-window__main"
            :location="selectedShop"
            @closeInfoWindow="closeInfoWindow"
            v-click-outside="closeInfoWindow"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="!generalLoading"
      class="main__content"
    >
      <div class="shops-block">
        <div class="shops-address sf-input sf-input--filled">
          <SfInput
            v-if="isAddressFieldShow"
            v-model="filter.searchString"
            type="text"
            :label="$t('Enter shop address')"
          />
          <div
            v-if="isAddressFieldShow"
            class="search-icon"
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.5507 13.5943C14.9318 12.2616 15.7897 10.398 15.7897 8.33569C15.7897 4.2843 12.4789 1 8.39487 1C4.3108 1 1 4.2843 1 8.33569C1 12.3871 4.3108 15.6714 8.39487 15.6714C10.4 15.6714 12.2187 14.8797 13.5507 13.5943ZM13.5507 13.5943L19 19" stroke="black" />
            </svg>
          </div>
          <MInputAutocomplete
            v-if="pageType === 'product'"
            :selected="selectedCity"
            :data-list="cities"
            :label="$t('City')"
            :message="$t('Enter the settlement from the list of results')"
            property="name"
            @set-value="changeCity"
          />
        </div>
        <div class="shops-tabs__wrap">
          <SfOTabs
            :open-tab="1"
            :tab-links="tabLinks"
            @tabClick="setTabNum"
          >
            <SfTab>
              <div class="shops-list" :class="{ 'shops-list--is-loading': shopsLoading }">
                <MShopListItem
                  v-for="shop in filteredShops"
                  :key="shop.id"
                  :shop="shop"
                  :selected-shop-id="selectedShopId"
                  :need-to-show-open-block="pageType === 'product'"
                  class="shop-item"
                  @shopClick="onShopClick"
                />

                <ALoadingSpinner
                    v-show="shopsLoading"
                    :size="48"
                    :weight="3"
                    :is-absolute-position="true"
                />
              </div>
            </SfTab>
            <SfTab>
              <div class="map">
                <OMap
                  :city="selectedCity"
                  :shops="filteredShops"
                  :page-type="pageType"
                  @open-info="openInfoWindow"
                  @close-info="closeInfoWindow"
                />
                <div
                  v-if="isShowTabletOnMapInfoWindow"
                  class="info-window"
                >
                  <MShopInfo
                    class="info-window__main"
                    :location="selectedShop"
                    @closeInfoWindow="closeInfoWindow"
                    v-click-outside="closeInfoWindow"
                  />
                </div>
              </div>
            </SfTab>
          </SfOTabs>
        </div>
      </div>
    </div>
    <MShopInfoSidebar
      :is-open="!!isShowSidebarInfoWindow"
      :selected-shop="selectedShop"
      @close="closeInfoWindow"
    />
    <div v-if="isShowTabletModalInfoWindow">
      <div class="info-window__overlay" />
      <div class="info-window info-window--with-overlay">
        <MShopInfo
          class="info-window__main"
          :location="selectedShop"
          @closeInfoWindow="closeInfoWindow"
          v-click-outside="closeInfoWindow"
        />
      </div>
    </div>

    <ALoadingSpinner
      v-show="generalLoading"
      :size="48"
      :weight="3"
      :is-absolute-position="true"
    />
  </ASmoothReflow>
</template>

<script>
import {
  SfInput
} from '@storefront-ui/vue';
import DeviceType from 'theme/mixins/DeviceType';
import MShopInfoSidebar from 'theme/components/molecules/m-shop-info-sidebar';
import MInputAutocomplete from 'theme/components/molecules/m-input-autocomplete';
import MShopInfo from 'theme/components/molecules/m-shop-info';
import MShopListItem from 'theme/components/molecules/m-shop-list-item';
import SfOTabs from 'src/themes/varus/components/storefront-override/SfOTabs';
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';
import { codes } from '$modules/shipping/config';

export default {
  name: 'OShops',
  components: {
    ASmoothReflow,
    SfInput,
    MShopInfoSidebar,
    MInputAutocomplete,
    MShopInfo,
    MShopListItem,
    SfOTabs,
    OMap: () => process.browser ? import('theme/components/organisms/o-map') : null,
    ALoadingSpinner
  },
  mixins: [DeviceType],
  directives: {
    clickOutside
  },
  props: {
    pageType: {
      type: String,
      required: true
    },
    stockShops: {
      type: Array,
      default: () => []
    },
    shops: {
      type: Array,
      default: () => []
    },
    cities: {
      type: Array,
      default: () => []
    },
    generalLoading: {
      type: Boolean,
      default: () => true
    },
    shopsLoading: {
      type: Boolean,
      default: () => true
    },
    currentCity: {
      type: Object,
      default: () => ({})
    },
    selectedCity: {
      type: Object,
      default: () => ({})
    },
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      selectedShopId: 0,
      openedTabNum: 1
    }
  },
  computed: {
    preparedShops () {
      const shops = this.shops.map(e => {
        const city = this.cities.find(city => Number(city.id) === Number(e.city_id))
        e.city_name = city?.name
        e.product_in_stock = this.pageType === 'product' && this.isProductInStock(e)
        // todo:: remove next line when attribute has_drive is done
        e.has_drive = this.getHasDriveByMethods(e)
        e.icon = this.isSelectedShop(e.id) ? '/assets/map-pin-selected-varus.svg' : '/assets/map-pin-varus.svg'
        return e
      })

      if (this.pageType === 'product') {
        shops.sort((a, b) => {
          return b.product_in_stock - a.product_in_stock
        })
      }

      return shops
    },
    filteredShops () {
      if (this.pageType === 'shops') {
        return this.filteredShopsOnShopsPage
      } else if (this.pageType === 'product') {
        return this.filteredShopsOnProductPage
      } else {
        return this.preparedShops
      }
    },
    filteredShopsOnShopsPage () {
      return this.preparedShops.filter(e => {
        const searchCity = !this.filter.searchString ||
          (e.city_name && e.city_name.toLowerCase().indexOf(this.filter.searchString.toLowerCase()) !== -1)
        const searchAddress = !this.filter.searchString ||
          (e.address && e.address.toLowerCase().indexOf(this.filter.searchString.toLowerCase()) !== -1)
        const searchPhone = !this.filter.searchString ||
          (e.phone && e.phone.toLowerCase().indexOf(this.filter.searchString.toLowerCase()) !== -1)

        return searchCity || searchAddress || searchPhone
      })
    },
    filteredShopsOnProductPage () {
      return this.preparedShops.filter(e => {
        return !this.filter.city || (e.city_id && Number(e.city_id) === Number(this.filter.city))
      })
    },
    tabLinks () {
      return [
        { title: 'In list' },
        { title: 'On map' }
      ]
    },
    isShowInfoWindow () {
      return (this.isDesktop || this.isLaptop) && this.selectedShopId
    },
    isShowTabletOnMapInfoWindow () {
      return this.isTablet && this.openedTabNum === 2 && this.selectedShopId
    },
    isShowTabletModalInfoWindow () {
      return this.isTablet && this.openedTabNum === 1 && this.selectedShopId
    },
    isShowSidebarInfoWindow () {
      return this.isMobile && this.selectedShopId
    },
    selectedShop () {
      return this.shops?.find(e => e.id === this.selectedShopId) || {}
    },
    isAddressFieldShow () {
      return this.pageType === 'shops' && this.openedTabNum === 1
    }
  },
  methods: {
    isSelectedShop (id) {
      return this.selectedShopId === id
    },
    onShopClick (location) {
      this.openInfoWindow(location)
      this.panToShop({
        lat: Number(location.lat),
        lng: Number(location.long)
      })

      this.selectedShopId = location.id
    },
    openInfoWindow (location) {
      this.selectedShopId = location.id
    },
    panToShop (position) {
      this.$bus.$emit('pan-map-to-position', position)
    },
    closeInfoWindow () {
      this.selectedShopId = 0
    },
    setTabNum (num) {
      this.selectedShopId = 0
      this.openedTabNum = num
    },
    changeCity (city) {
      this.$emit('change-city', city)
    },
    isProductInStock (shop) {
      return this.stockShops.includes(+shop.id)
    },
    getHasDriveByMethods (shop) {
      const deliveryMethods = shop?.delivery_methods || []
      const hasDrive = deliveryMethods.filter(method => method.key === codes.varusDrive).length > 0
      return hasDrive ? '1' : '0'
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.o-shops {
  flex-basis: 100%;
  position: relative;
  width: 100%;
  min-height: px2rem(100);

  .main__content {
      @include for-desktop {
        display: flex;
        flex-direction: row;
        border: 1px solid var(--gray3);
      }

      .shops-block {
        @include for-desktop {
          flex-basis: 33.8%;
          min-width: px2rem(400);
        }

        .shops-address {
          position: relative;

          ::v-deep {
            .sf-input__error-message {
              display: none;
            }
          }

          @include for-desktop {
            padding: var(--spacer-20) var(--spacer-20) var(--spacer-15) var(--spacer-20);
          }

          .search-icon {
            position: absolute;
            right: 1rem;
            top: calc(50% - var(--spacer-7));

            @include for-desktop {
              right: 2rem;
            }
          }
        }

        .address-separator {
          margin: 0 var(--spacer-20);
          border-bottom: 1px solid var(--gray3);
        }

        .shops-tabs__wrap {
          min-height: var(--spacer-100);
          box-sizing: border-box;

          ::v-deep {
            .sf-tabs {
              &__title {
                display: none;
              }

              &__content__tab {
                padding: 0;
              }

              --tabs-title-z-index: 1;
              --tabs-content-order: 1;
              --tabs-title-flex: 0 0 auto;
              --tabs-title-margin: 0 var(--spacer-lg) -2px 0;
              --tabs-title-padding: var(--spacer-xs) 0;
              --tabs-title-color: var(--c-text-muted);
              --tabs-title-font-size: var(--h4-font-size);
              --tabs-content-tab-padding: var(--spacer-base) 0;
              --tabs-chevron-display: none;
            }
          }

          .orders-tab {
            margin-top: var(--spacer-20);

            @media (max-width: $mobile-max) {
              margin-top: var(--spacer-15);
            }
          }
        }

        .shops-list {
          &--is-loading {
            min-height: px2rem(100);
          }

          height: 100%;
          max-height: px2rem(469);
          scrollbar-width: thin;
          scrollbar-color: var(--orange);
          overflow-y: auto;
          position: relative;

          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(--orange);
          }

          .shop-item {
            padding-top: var(--spacer-20);
          }
        }
      }

      .map {
        position: relative;
        flex-grow: 1;
        height: px2rem(569);
        background-color: var(--yellow-corn-silk);
        box-sizing: border-box;

        .info-window {
          position: absolute;
          top: 20px;
          right: 20px;
          background-color: var(--white);
          box-shadow: var(--spacer-5) var(--spacer-5) var(--spacer-10) var(--spacer-5) rgba(0, 0, 0, 0.2);
          width: px2rem(425);
          height: px2rem(520);

          .info-window__main {
            padding: var(--spacer-30);
          }
        }
      }
    }

  .info-window__overlay {
    position: fixed;
    background-color: rgba(51, 51, 51, 0.7);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: var(--overlay-z-index);
  }

  .info-window.info-window--with-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    background-color: var(--white);
    box-shadow: var(--spacer-10) var(--spacer-10) var(--spacer-10)  0 rgba(0, 0, 0, .2);
    width: px2rem(425);
    height: px2rem(520);
    z-index: calc(var(--overlay-z-index) + 1);

    .info-window__main {
      padding: var(--spacer-30);
    }
  }
}
</style>
