
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object,
      default: () => ({})
    },
    filtersCount: {
      type: Number,
      default: 0
    },
    citiesList: {
      type: Array,
      default: () => []
    },
    scheduleList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      defaultCity: {
        isDefault: true,
        name: this.$t('All cities')
      },
      defaultShedule: {
        isDefault: true,
        name: this.$t('Any time'),
        label: this.$t('Any time')
      },
      appliedFilter: {
        isDefault: false,
        searchString: '',
        city: '',
        schedule: '',
        cafe: false,
        drive: false
      }
    }
  },
  computed: {
    selectedCity () {
      const findCity = city => city.id === this.filter.city || this.filter.isDefault
      return this.citiesList.find(findCity) || this.defaultCity
    },
    selectedSchedule () {
      const findSchedule = schedule => schedule.name === this.filter.schedule || this.filter.isDefault
      return this.scheduleList.find(findSchedule) || this.defaultShedule
    }
  },
  mounted () {
    this.appliedFilter = JSON.parse(JSON.stringify(this.filter))
  },
  methods: {
    changeCity (city) {
      const { id, isDefault } = city
      this.appliedFilter.city = isDefault ? '' : id
      this.applyFilter()
    },
    changeSchedule (schedule) {
      const { name, isDefault } = schedule
      this.appliedFilter.schedule = isDefault ? '' : name
      this.applyFilter()
    },
    changeVarusCafe (cafe) {
      this.appliedFilter.cafe = cafe
      this.applyFilter()
    },
    changeVarusDrive (drive) {
      this.appliedFilter.drive = drive
      this.applyFilter()
    },
    clearFilter () {
      this.appliedFilter = {
        isDefault: false,
        searchString: '',
        city: '',
        schedule: '',
        cafe: false,
        drive: false
      }
    },
    resetFilter () {
      this.clearFilter()
      this.applyFilter()
    },
    applyFilter () {
      this.$emit('apply', this.appliedFilter)
    }
  }
}
