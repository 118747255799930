<template>
  <div id="shops" class="shops">
    <m-breadcrumbs />
    <div class="section-head">
      <SfHeading
        :level="1"
        :title="$t('Shops')"
        class="section-head__title"
      />
    </div>
    <div class="main">
      <div class="main__filters">
        <MSidebarMobileButtons
          :filters-count="activeFiltersCount"
          :categories-title="$t('City')"
          :categories-are-shown="citiesAreShown"
          @open="isFilterSidebarOpen = true"
          @toggle-categories="toggleCitiesFilters()"
        />
        <div class="shop-cities__wrap">
          <MShopsCities
            v-if="!isDesktop && citiesAreShown"
            :cities="cities"
            @itemClick="changeCity"
            class="shop-cities"
          />
        </div>
        <MShopsFilters
          v-if="isDesktop"
          :filters-count="activeFiltersCount"
          :cities-list="cities"
          :schedule-list="getSchedule"
          :filter="filter"
          @apply="onFiltersApply"
        />
        <MShopsFiltersSidebar
          :is-open="!!isFilterSidebarOpen"
          :filters-count="activeFiltersCount"
          :cities-list="cities"
          :schedule-list="getSchedule"
          :filter="filter"
          @apply="onFiltersApply"
          @close="onMobileFiltersClose"
        />
      </div>
      <no-ssr>
        <OShops
          class="o-shops-wrap"
          page-type="shops"
          :shops="filteredShops"
          :cities="cities"
          :current-city="currentCity"
          :selected-city="selectedCity"
          :general-loading="loading.general"
          :shops-loading="loading.shops"
          :filter="filter"
        />
      </no-ssr>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  SfHeading
} from '@storefront-ui/vue';
import i18n from '@vue-storefront/i18n';
import DeviceType from 'theme/mixins/DeviceType';
import MBreadcrumbs from 'theme/components/molecules/m-breadcrumbs';
import MSidebarMobileButtons from 'theme/components/molecules/m-sidebar-mobile-buttons';
import MShopsFilters from 'theme/components/molecules/m-shops-filters';
import MShopsFiltersSidebar from 'theme/components/molecules/m-shops-filters-sidebar';
import MShopsCities from 'theme/components/molecules/m-shops-cities';
import OShops from 'theme/components/organisms/o-shops';
import { isServer } from '@vue-storefront/core/helpers';
import NoSSR from 'vue-no-ssr';
import ShippingShopsMixin from '$modules/shipping/mixins/shipping-shops-mixin';
import { metaShops } from 'theme/meta/shops';

export default {
  name: 'Shops',
  components: {
    MShopsCities,
    SfHeading,
    MBreadcrumbs,
    MSidebarMobileButtons,
    MShopsFilters,
    MShopsFiltersSidebar,
    OShops,
    'no-ssr': NoSSR
  },
  mixins: [DeviceType, ShippingShopsMixin],
  data () {
    return {
      citiesAreShown: false,
      isFilterSidebarOpen: false
    }
  },
  computed: {
    ...mapGetters({
      getAllShops: 'shipping/getAllShops'
    }),
    filteredShops () {
      return this.shops.filter(e => {
        const city = !this.filter.city || (e.city_id && Number(e.city_id) === Number(this.filter.city))
        const schedule = this.isScheduleMatch(e.work_time, this.filter.schedule)
        const cafe = !this.filter.cafe || e.has_cafe === '1'
        const drive = !this.filter.drive || e.has_drive === '1'

        return city && schedule && cafe && drive
      })
    },
    getSchedule () {
      let result = this.shops.map(e => e.work_time)
      result.sort();
      result = Array.from(new Set(result))

      return [
        {
          isDefault: true,
          name: this.$t('Any time'),
          label: this.$t('Any time')
        },
        ...result.map(e => ({ name: e, isDefault: false }))
      ]
    },
    activeFiltersCount () {
      let count = 0
      if (this.filter.searchString) count++
      if (this.filter.city) count++
      if (this.filter.schedule) count++
      if (this.filter.cafe) count++
      if (this.filter.drive) count++
      return count
    },
    citiesList () {
      return [
        {
          isDefault: true,
          name: this.$t('All cities')
        },
        ...this.cities.map(item => ({ isDefault: false, ...item }))
      ]
    }
  },
  async asyncData ({ store }) {
    await store.dispatch('shipping/loadCitiesList', null)
    await store.dispatch('ui/loadCatalogMenu')
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next();
      return
    }

    next(async vm => {
      vm.$store.dispatch('shipping/loadAllShopsList')
    });
  },
  mounted () {
    this.setBreadcrumbs()
    this.loadShops()
      .finally(() => {
        this.loading.general = false
      })
  },
  methods: {
    ...mapActions({
      breadcrumbsSet: 'breadcrumbs/set'
    }),

    setBreadcrumbs () {
      const routes = []

      this.breadcrumbsSet({
        current: i18n.t('Shops'),
        routes: routes
      }, { root: true })
    },
    toggleCitiesFilters () {
      this.citiesAreShown = !this.citiesAreShown
    },
    changeCity (city) {
      this.citiesAreShown = false
      this.setFilters({
        city: city.id
      })
    },
    onFiltersApply (filter) {
      this.setFilters(filter)
    },
    onMobileFiltersClose () {
      this.isFilterSidebarOpen = false
    },
    compareWorkTime (workTime, sсhedule) {
      const [startDate, endDate] = sсhedule.split('-')
      const [startDateWork, endDateWork] = workTime.split('-')

      return startDateWork >= startDate && endDateWork <= endDate
    },
    isScheduleMatch (workTime, filterSchedule) {
      if (!filterSchedule) return true;
      return workTime && this.compareWorkTime(workTime, filterSchedule)
    }
  },
  metaInfo: metaShops
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

.shops {
  max-width: var(--max-width);
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  padding: 0 var(--spacer-15) var(--spacer-100);

  @media (max-width: $mobile-max) {
    padding: 0 var(--spacer-10) var(--spacer-15);
  }

  @include for-tablet {
    padding: 0 var(--spacer-17) var(--spacer-15);
  }

  ::v-deep {
    .sf-breadcrumbs {
      padding: var(--spacer-12) 0 var(--spacer-17);

      @include for-desktop {
        padding: var(--spacer-10) 0 var(--spacer-30);
      }

      &__list-item {
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .main {
    display: flex;
    flex-direction: column;

    .o-shops-wrap {
      @include for-desktop {
        margin-top: var(--spacer-30);
      }

      @media (max-width: $mobile-max) {
        margin-top: var(--spacer-10);
      }

      @include for-tablet {
        margin-top: var(--spacer-10);
      }
    }

    &__filters {
      margin-top: var(--spacer-20);

      @media (max-width: $mobile-max) {
        margin-top: var(--spacer-10);
      }

      @include for-tablet {
        margin-top: var(--spacer-20);
      }

      .shop-cities__wrap {
        position: relative;

        .shop-cities {
          position: absolute;
          width: 100%;
          z-index: 2;
        }
      }
    }
  }

  .section-head {
    &__title {
      display: inline;

      ::v-deep .sf-heading__title {
        padding: 0;
        display: inline;
        @include header-title;
      }
    }
  }
}
</style>
